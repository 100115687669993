const slider = document.getElementById("banner-slider");
const right = document.querySelectorAll(".banner-slider-right");
const border = document.querySelectorAll(".banner-slider-border");

document.addEventListener("DOMContentLoaded", () => {
  right.forEach((r, ind) =>
    r.addEventListener("mousemove", (e) => {
      border[ind].classList.add("show");
      const x = e.clientX;
      const y = e.clientY;

      border[ind].style.left = `${x}px`;
      border[ind].style.top = `${y}px`;
    })
  );

  right.forEach((r, ind) => {
    r.addEventListener("mouseleave", () => {
      border[ind].classList.remove("show");
    });
    right.forEach((r, ind) => {
      r.addEventListener("click", (e) => {
        console.log(e.target);
        const url = r.getAttribute("data-href");
        if (url) {
          window.location.href = url;
        }
      });
    });
  });
});
const progressCircle = document.querySelector(".autoplay-progress svg");
const progressContent = document.querySelector(".autoplay-progress span");
var swiper = new Swiper(".banner-swiper", {
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: true,
  },

  spaceBetween: 0,
  centeredSlides: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  on: {
    autoplayTimeLeft(s, time, progress) {
      progressCircle.style.setProperty("--progress", 1 - progress);
      progressContent.textContent = `${Math.ceil(time / 1000)}s`;
    },
  },
});
var swiper = new Swiper(".price-swiper", {
  slidesPerView: 4,
  freeMode: true,
  spaceBetween: window.innerWidth * 0.1,
  breakpoints: {
    150: {
      slidesPerView: 1.3,
    },
    600: {
      slidesPerView: 2.3,
    },
    1200: {
      slidesPerView: 3,
    },
    1500: {
      slidesPerView: 3,
    },
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const tile = document.querySelector(".tiles");

const tilesItems = document.querySelectorAll(".tiles ol li");
const rect = tile.getBoundingClientRect();
document.addEventListener("scroll", () => console.log(scrollY - rect.top));
console.log(rect);
